<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="title">
          <el-input
              v-model="query.title"
              placeholder="请输入-标题内容"
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="couponType">
          <el-select
              v-model="query.couponType"
              placeholder="请选择-业务类型"
              style="width: 260px"
          >
            <el-option label="通用券" value="0"></el-option>
            <el-option label="停车券" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="ruleType">
          <el-select
              v-model="query.ruleType"
              placeholder="请选择-优惠券类型"
              style="width: 260px"
          >
            <el-option label="面额券" value="0"></el-option>
            <el-option label="免费时长券" value="1"></el-option>
            <el-option label="免费停车券" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="bearerId">
          <!-- <Bearer v-model="query.bearerId" style="width: 260px" /> -->
          <el-select
              v-model="query.bearerId"
              placeholder="请选择-承担方"
              style="width: 260px"
          >
            <el-option label="平台" value="0"></el-option>
            <el-option label="结算方" value="1"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-select v-model="query.region" placeholder="请选择-是否转赠" style="width: 260px">
            <el-option label="是" value="0"></el-option>
            <el-option label="否" value="1"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item prop="status">
          <el-select
              v-model="query.status"
              placeholder="请选择-状态"
              style="width: 260px"
          >
            <el-option label="未使用" value="1"></el-option>
            <el-option label="已使用" value="2"></el-option>
            <el-option label="已过期" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="eventId">
          <!-- <el-select v-model="formInline.region" placeholder="请选择-触发事件" style="width: 260px">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select> -->
          <TriggerEvent v-model="query.eventId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="couponCode">
          <el-input
              v-model="query.couponCode"
              placeholder="优惠券编号"
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="couponRuleCode">
          <el-input
              v-model="query.couponRuleCode"
              placeholder="发行编号"
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="paySettleAccountId">
          <!-- <el-input
            v-model="query.paySettleAccountId"
            placeholder="请输入结算方名称"
            style="width: 260px"
          ></el-input> -->
          <Settlement v-model="query.paySettleAccountId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="parkingLotId">
          <!-- <el-input
            v-model="formInline.user"
            placeholder="请输入使用车场名称"
            style="width: 260px"
          ></el-input> -->
          <SuggestParking v-model="query.parkingLotId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="parkingOrderId">
          <el-input
              v-model="query.parkingOrderId"
              placeholder="订单编号"
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="operator">
          <el-select
              v-model="query.operator"
              placeholder="请选择操作人"
              style="width: 260px"
          >
            <el-option label="用户" value="0"></el-option>
            <el-option label="商户" value="1"></el-option>
            <el-option label="平台" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="xxd-input__wrap" style="width: 530px">
            领取时间：
            <el-date-picker
                v-model="receiveValue"
                end-placeholder="结束日期"
                prefix-icon="none"
                range-separator="~"
                start-placeholder="开始日期"
                type="daterange"
                unlink-panels
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="xxd-input__wrap" style="width: 530px">
            使用时间：
            <el-date-picker
                v-model="useValue"
                end-placeholder="结束日期"
                prefix-icon="none"
                range-separator="~"
                start-placeholder="开始日期"
                type="daterange"
                unlink-panels
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item prop="useAccount">
          <el-input
              v-model="query.useAccount"
              placeholder="请输入领取手机号"
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="bindVehicleId">
          <NumplateSuggest
              v-model="query.bindVehicleId"
              placeholder="请输入领取车牌号"
              style="width: 260px"
          />
        </el-form-item>
        <el-form-item prop="useVehicle">
          <NumplateSuggest
              v-model="query.useVehicle"
              placeholder="请输入使用车辆"
              style="width: 260px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="goSearch">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共计<span class="total-size">{{ total }}</span
        >条信息
        </div>
        <div class="op-extra">
          <icon-button
              v-if="hasRights('001008003001')"
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="useDataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="标题" prop="title" width="180">
          </el-table-column>
          <el-table-column label="发行编号" prop="couponRuleCode" width="220">
          </el-table-column>
          <el-table-column label="触发事件" prop="eventName" width="180">
          </el-table-column>
          <el-table-column label="承担方" prop="bearerName" width="180">
          </el-table-column>
          <el-table-column label="适用业务类型" prop="couponType" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.couponType === 0 ? "通用" : "停车券" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="优惠券类型" prop="ruleType" width="180">
            <template slot-scope="scope">
              <span>{{
                  scope.row.ruleType === 0
                      ? "面额券"
                      : scope.row.ruleType === 1
                          ? "免费时长券"
                          : "免费停车券"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="优惠额度(元)" prop="faceAmount" width="180">
            <template slot-scope="scope">
              <span>{{ parseFloat(scope.row.faceAmount).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="结算方"
              prop="paySettleAccountName"
              width="180"
          >
          </el-table-column>
          <!-- <el-table-column prop="name" label="是否转赠" width="180">
          </el-table-column> -->
          <el-table-column label="领取账户" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.receiveAccount !== undefined">
                <span v-if="scope.row.receiveAccount.useAccount === ''">
                  <Numplate :type="scope.row.receiveAccount.autoType">{{
                      scope.row.receiveAccount.plateNumber
                    }}</Numplate>
                </span>
                <span v-else>{{ scope.row.receiveAccount.useAccount }}</span>
              </span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="使用账户" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.receiveAccount !== undefined">
              <span>{{scope.row.useAccount.useAccount === ""  ? "" : scope.row.useAccount.useAccount}}</span>
              </span>
            </template>
          </el-table-column> -->
          <el-table-column label="使用车辆" prop="vehicle" width="180">
            <!-- <template slot-scope="scope">
              <span v-if="scope.row.status === 2">{{scope.row.vehicle}}</span>
            </template> -->
            <template slot-scope="scope">
              <span v-if="scope.row.receiveAccount !== undefined">
                <span v-if="scope.row.status === 2">
                  <Numplate :type="scope.row.useAccount.autoType">{{
                      scope.row.useAccount.plateNumber
                    }}</Numplate>
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="抵扣额" prop="discountAmount" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 2">{{
                  parseFloat(scope.row.discountAmount).toFixed(2)
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作人" width="180">
            <template slot-scope="scope">
              <span>{{
                  scope.row.operator === 0
                      ? "用户"
                      : scope.row.operator === 1
                          ? "商户"
                          : "平台"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="领取时间" prop="receiveDate" width="180">
          </el-table-column>
          <el-table-column label="使用时间" prop="useDate" width="180">
          </el-table-column>
          <el-table-column label="使用车场" prop="parkingLotName" width="180">
          </el-table-column>
          <el-table-column label="订单编号" prop="parkingOrderId" width="300">
          </el-table-column>
          <!-- <el-table-column prop="name" label="第三方业务编号" width="180">
          </el-table-column> -->
          <el-table-column label="优惠券状态" prop="status" width="180">
            <template slot-scope="scope">
              <span>{{
                  scope.row.status === 1
                      ? "未使用"
                      : scope.row.status === 2
                          ? "已使用"
                          : "已过期"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              fixed="right"
              label="操作"
              prop="name"
              width="100px"
          >
            <template slot-scope="scope">
              <el-button
                  v-if="hasRights('001008003002')"
                  size="small"
                  style="font-size: 14px"
                  type="text"
                  @click="goDetail(scope.row.id)"
              >详情
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
              :current-page="pagination.page"
              :page-size="pagination.size"
              :page-sizes="[10, 15, 30, 50]"
              :total="pagination.totalSize"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TriggerEvent from "@/components/TriggerEvent";
import Settlement from "@/components/Settlement";
import SuggestParking from "@/components/SuggestParking";
// import Bearer from "@/components/Bearer";
import {getCouponUseList} from "@/api/coupon";
import Numplate from "@/components/Numplate";
import NumplateSuggest from "@/components/NumplateSuggest";
import {doExport} from "@/api/common";
import Permission from "@/mixins/permission";

export default {
  name: "use-manage",
  mixins: [Permission],
  data() {
    return {
      receiveValue: [],
      useValue: [],
      total: 0,
      query: {
        region: "", //是否转赠
        title: "",
        couponType: "",
        ruleType: "",
        bearerId: "",
        status: "",
        eventId: "",
        couponCode: "",
        couponRuleCode: "", //发行编号  优惠券规则？？？
        paySettleAccountId: "",
        parkingLotId: "",
        parkingOrderId: "",
        useAccount: "",
        bindVehicleId: "",
        useVehicle: "",
        operator: "",
        receiveBegin: "",
        receiveEnd: "",
        useBegin: "",
        useEnd: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      pagination: {
        page: 1,
        size: 10,
        totalSize: 0,
        totalPages: 1,
      },
      isLoading: false,
      useDataList: [],
    };
  },
  components: {
    TriggerEvent,
    Settlement,
    SuggestParking,
    // Bearer,
    Numplate,
    NumplateSuggest,
  },
  methods: {
    //查看使用优惠券详情
    goDetail(e) {
      this.$router.push({
        path: "/coupon_manage/use_detail",
        query: {
          id: e,
        },
      });
    },
    //重置查询条件
    resetForm() {
      this.$refs.query.resetFields();
      this.receiveValue = [];
      this.useValue = [];
      // this.query.useBegin = "";
      // this.query.useEnd = "";
      this.goSearch();
    },
    paramFormat(query) {
      const {page, size} = this.pagination;

      return Object.assign(query, {
        page,
        size,
      });
    },
    //查询列表
    goSearch() {
      this.pagination.page = 1;
      this.pagination.size = 10;
      this.search();
    },
    search() {
      this.isLoading = true;
      const params = this.paramFormat(this.query);
      if (this.receiveValue.length !== 0) {
        params["receiveBegin"] = this.receiveValue[0] + " 00:00:00";
        params["receiveEnd"] = this.receiveValue[1] + " 23:59:59";
      }
      if (this.useValue.length !== 0) {
        params["useBegin"] = this.useValue[0] + " 00:00:00";
        params["useEnd"] = this.useValue[1] + " 23:59:59";
      }
      getCouponUseList(params).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.useDataList = res.returnObject.records;
          this.pagination.totalSize = res.returnObject.total;
          this.pagination.totalPages = res.returnObject.pages;
          this.total = res.returnObject.total;
          this.form.exportSize = res.returnObject.total;
        }
      });
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.receiveValue.length !== 0) {
        params["receiveBegin"] = this.receiveValue[0] + " 00:00:00";
        params["receiveEnd"] = this.receiveValue[1] + " 23:59:59";
      }
      if (this.useValue.length !== 0) {
        params["useBegin"] = this.useValue[0] + " 00:00:00";
        params["useEnd"] = this.useValue[1] + " 23:59:59";
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport(
          "/couponController/getCouponUseList",
          params,
          "优惠券使用管理.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    handleSizeChange(e) {
      this.pagination.size = e;
      this.search();
    },
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.search();
    },
  },
  mounted() {
    this.goSearch();
  },
};
</script>

<style lang="less" scoped>
</style>
